<template>
  <div>
    <v-row no-gutters>
      <v-spacer></v-spacer>
      <v-col cols="12" md="4">
        <v-layout justify-end align-center>
          <p
            v-if="customerId || getUnpaidSum"
            :class="
              isSales ? 'mr-4 mb-0 font-weight-bold' : 'mr-4 font-weight-bold'
            "
          >
            {{
              $t("Unpaid sum") +
              " " +
              currencyCodeToSign(getCurrencyCode) +
              getUnpaidSum
            }}
          </p>

          <v-btn
            fab
            small
            elevation="2"
            :class="isSales ? 'mr-4' : 'mb-4'"
            @click="dialog = true"
            :disabled="getButtonCheck"
            :loading="isDownloadingInvoices"
          >
            <v-icon> mdi-download </v-icon>
          </v-btn>
          <v-autocomplete
            v-if="isSales"
            v-model="customerId"
            :items="getSearchCustomers"
            :loading="searchingCustomerFilter"
            :search-input.sync="searchCustomerWatchFilter"
            item-text="Name"
            item-value="Id"
            clearable
            hide-no-data
            :placeholder="$t('Customer')"
            @change="getAccountStatments()"
            return-object
          >
          </v-autocomplete>
        </v-layout>
      </v-col>
    </v-row>
    <v-card v-show="showCard">
      <v-card-text>
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">{{ $t("Booking date") }}</th>
                <th class="text-left">{{ $t("Document number") }}</th>
                <th class="text-left">{{ $t("Description") }}</th>
                <th class="text-left">{{ $t("Expiration date") }}</th>
                <th class="text-left">{{ $t("Original amount") }}</th>
                <th class="text-left">{{ $t("Remaining amount") }}</th>
              </tr>
            </thead>
            <tbody
              v-if="!isLoadingAccountStatements && accountStatements.length"
            >
              <tr
                v-for="(accountStatement, index) in accountStatements"
                :key="index"
              >
                <td>
                  {{ changeToDMY(accountStatement.PostingDate) }}
                </td>
                <td>
                  {{ accountStatement.DocumentNo }}
                </td>
                <td>
                  {{ accountStatement.Description }}
                </td>
                <td>
                  {{ changeToDMY(accountStatement.DueDate) }}
                </td>
                <td>
                  {{
                    currencyCodeToSign(accountStatement.CurrencyCode) +
                    " " +
                    accountStatement.Amount
                  }}
                </td>
                <td>
                  {{
                    currencyCodeToSign(accountStatement.CurrencyCode) +
                    " " +
                    accountStatement.RemainingAmount
                  }}
                </td>
              </tr>
            </tbody>
            <tbody v-else-if="accountStatements.length == 0">
              <tr>
                <td colspan="6">
                  <v-layout
                    justify-center
                    align-center
                    fill-height
                    class="my-4"
                  >
                    {{ $t("No data available") }}
                  </v-layout>
                </td>
              </tr>
            </tbody>
            <tbody v-else>
              <tr>
                <td colspan="6">
                  <preloader />
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card-text>
    </v-card>
    <v-dialog v-model="dialog" persistent max-width="700px">
      <v-card>
        <v-card-title>
          <span class="text-h5">{{ $t("Download overview") }}</span>
        </v-card-title>
        <v-card-text>
          <v-layout justify-space-between align-center class="mb-8">
            <div>
              <p>{{ $t("Start date") }}</p>
              <v-date-picker
                :locale="getBCPLangCode"
                v-model="startDate"
              ></v-date-picker>
            </div>
            <div>
              <p>{{ $t("End date") }}</p>
              <v-date-picker
                :locale="getBCPLangCode"
                v-model="endDate"
              ></v-date-picker>
            </div>
          </v-layout>
          <v-layout justify-end align-center class="mt-4">
            <v-btn
              @click="dialog = false"
              :disabled="isDownloadingInvoices"
              class="mr-4"
            >
              {{ $t("Cancel") }}
            </v-btn>
            <v-btn
              color="primary"
              @click="downloadInvoices()"
              :disabled="!startDate || !endDate"
              :loading="isDownloadingInvoices"
            >
              {{ $t("Download") }}
            </v-btn>
          </v-layout>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
    
    <script>
import request from "../../request";
import helpers from "../../helpers";
import Preloader from "./Preloader.vue";
import auth from "../../auth";

export default {
  components: { Preloader },
  props: {
    isSales: {
      type: Boolean,
    },
  },
  data() {
    return {
      accountStatements: [],
      isDownloading: [],
      searchCustomers: [],
      searchCustomerWatchFilter: "",
      searchingCustomerFilter: false,
      customerId: null,
      isLoadingAccountStatements: false,
      timerSearchCustomer: null,
      dialog: false,
      startDate: this.getDate(6),
      endDate: this.getDate(),
      unpaidAmount: 0,
      isDownloadingInvoices: false,
      userCurrency: null,
    };
  },
  created() {
    if (!this.isSales) {
      this.getAccountStatments();
      request.get("/api/kdesign/account", null, (res) => {
        this.userCurrency = res.Currency;
      });
    }
  },
  computed: {
    currentLanguage: {
      get: function () {
        return this.$i18n.locale();
      },
    },
    getCurrencyCode() {
      if (this.customerId) {
        return this.customerId.Currency;
      }
      return this.userCurrency;
    },
    getButtonCheck() {
      if (this.isSales && this.customerId) {
        return false;
      } else if (!this.isSales && this.accountStatements.length) {
        return false;
      }
      return true;
    },
    getUnpaidSum() {
      return this.unpaidAmount;
    },
    getSearchCustomers() {
      if (!this.searchCustomers) {
        return [];
      }
      this.searchCustomers.forEach((element) => {
        element.Name = element.Name + " (" + element.Code + ")";
      });
      return this.searchCustomers;
    },
    showCard() {
      if (this.isSales && !this.customerId) {
        return false;
      }
      return true;
    },
    getBCPLangCode() {
      let lang;
      switch (this.currentLanguage) {
        case "Nl":
          lang = "nl-BE";
          break;
        case "Fr":
          lang = "fr-FR";
          break;
        case "En":
          lang = "en-GB";
          break;
      }

      return lang;
    },
  },
  watch: {
    searchCustomerWatchFilter: function (searchValue) {
      if (searchValue == null) {
        return;
      }
      clearTimeout(this.timerSearchCustomer);
      this.timerSearchCustomer = setTimeout(() => {
        if (searchValue != "") {
          this.searchCustomerFilter(searchValue);
        }
      }, 200);
    },
    searchCustomers: function () {
      this.getUnpaidSumFunc();
    },
  },
  methods: {
    downloadInvoices() {
      this.isDownloadingInvoices = true;

      let user = auth.getUser();
      let customerId = user._id;

      if (this.isSales) {
        customerId = this.customerId.Id;
      }
      request.download(
        "/api/kdesign/b2b-customer-account-statement-download" +
          "?customerId=" +
          customerId +
          "&startDate=" +
          this.startDate +
          "&endDate=" +
          this.endDate,
        { responseType: "blob" },
        this.$t("account_statement") +
          "-" +
          this.startDate +
          "_" +
          this.endDate +
          ".pdf",
        (res) => {
          this.isDownloadingInvoices = false;
          if (res.success) {
            this.dialog = false;
          } else {
            this.$store.dispatch(
              "setError",
              this.$t("Woops, something went wrong! Please try again.")
            );
          }
        }
      );
    },
    getDate(offset = 0) {
      var today = new Date();
      if (offset !== 0 && !isNaN(offset)) {
        today.setMonth(today.getMonth() - offset);
      }

      let day = today.getDate();
      let month = today.getMonth() + 1; // Month is zero-indexed, so we add 1
      let year = today.getFullYear();

      // Pad day and month with leading zero if needed
      day = day < 10 ? "0" + day : day;
      month = month < 10 ? "0" + month : month;
      return year + "-" + month + "-" + day;
    },
    getUnpaidSumFunc() {
      if (this.isSales && !this.customerId) {
        return 0;
      }
      let customerId = this.isSales ? "?customerId=" + this.customerId.Id : "";
      request.get(
        "/api/kdesign/b2b-customer-open-documents-total" + customerId,
        null,
        (res) => {
          if (res.success) {
            this.unpaidAmount = res.item;
          } else {
            this.$store.dispatch("setError", res);
          }
        }
      );
    },
    getAccountStatments() {
      this.isLoadingAccountStatements = true;
      const id = this.customerId ? "?customerId=" + this.customerId.Id : "";
      const url = "/api/kdesign/b2b-customer-account-statement" + id;
      request.get(url, null, (res) => {
        this.isLoadingAccountStatements = false;

        if (res.success) {
          this.getUnpaidSumFunc();
          this.accountStatements = res;
        }
      });
    },
    searchCustomerFilter(search) {
      if (this.searchingCustomerFilter) return;
      this.searchingCustomerFilter = true;
      request.get(
        "/api/kdesign/customers?search=" + search + "&getAll=true",
        null,
        (res) => {
          this.searchCustomers = res;
          this.searchingCustomerFilter = false;
        }
      );
    },
    currencyCodeToSign(item) {
      let currencySign;
      switch (item) {
        case "EUR":
          currencySign = "€ ";
          break;
        case "GBP":
          currencySign = "£ ";
          break;
        case "USD":
          currencySign = "$ ";
          break;

        default:
          currencySign = "€ ";
          break;
      }
      return currencySign;
    },
    downloadDocument(doc, index) {
      this.isDownloading[index] = true;
      const file = doc.Data.File.Value[this.currentLanguage]
        ? doc.Data.File.Value[this.currentLanguage][0]
        : doc.Data.File.Value["Nl"][0];

      request.download(
        "/files/" + doc.WebsiteId.$oid + "/" + file,
        { responseType: "blob" },
        file,
        () => {
          this.isDownloading[index] = false;
        }
      );
    },
    changeToDMY(item) {
      return helpers.changeTimeToReadebleDMY(item);
    },
  },
};
</script>
    
<style scoped>
.height-card {
  height: 75vh;
}
</style>