<template>
  <AccountStatement :isSales="false" />
</template>
  
  <script>
import AccountStatement from "../shared/AccountStatement.vue";
export default {
  components: {
    AccountStatement,
  },
};
</script>
  
  <style>
</style>